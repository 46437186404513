<template>
  <div class="ui-axios" v-loading="props.displayLoading && isLoading">
    <!-- 这个组件本身不需要有任何模板内容，因为它只是提供函数 -->
  </div>
</template>

<script setup>
  import { ref, onUnmounted, defineProps, defineExpose } from 'vue'
  import { http_get, http_post } from '@/axios'

  /**
   * 组件参数
   */
  const props = defineProps({
    /**
     * 显示加载
     */
    displayLoading: {
      type: Boolean,
      default: true,
    },
  })

  // 存储所有请求的 AbortController
  const controllers = []
  const isLoading = ref(false)

  /**
   * 从 controllers 中移除指定的 controller
   * @param controller 要移除的 AbortController 实例
   */
  function removeController(controller) {
    const index = controllers.indexOf(controller)
    if (index !== -1) {
      controllers.splice(index, 1)
    }
  }

  /**
   * 发送GET请求
   * @param url 请求地址 /user/login
   * @param params 请求参数
   * @param displayTip 请求成功和失败是否显示提示
   * @param tipType 请求失败时的样式，在displayTip为true时生效。 1对话框形式, 2顶部信息提示
   * @param timeout 请求超时时间, 默认15秒
   * @param onProgress 请求进度
   * @returns {Promise<unknown>}
   */
  async function get(url, params = {}, displayTip = false, tipType = 1, timeout = 15000, onProgress = undefined) {
    const controller = new AbortController()
    controllers.push(controller)

    return new Promise((resolve, reject) => {
      isLoading.value = true
      http_get(url, params, displayTip, tipType, timeout, onProgress, controller.signal)
        .then((data) => {
          resolve(data)
        })
        .catch(({ status, msg, data }) => {
          reject({ status, msg, data })
        })
        .finally(() => {
          isLoading.value = false
          removeController(controller)
        })
    })
  }

  /**
   * 发送POST请求
   * @param url 请求地址 /user/login
   * @param data 提交数据
   * @param displayTip 请求成功和失败是否显示提示
   * @param tipType 请求失败时的样式，在displayTip为true时生效。 1对话框形式, 2顶部信息提示
   * @param timeout 请求超时时间, 默认15秒
   * @param onProgress 请求进度
   * @returns {Promise<unknown>}
   */
  function post(url, data = {}, displayTip = false, tipType = 1, timeout = 15000, onProgress = undefined) {
    const controller = new AbortController()
    controllers.push(controller)

    return new Promise((resolve, reject) => {
      isLoading.value = true
      http_post(url, data, displayTip, tipType, timeout, onProgress, controller.signal)
        .then((data) => {
          resolve(data)
        })
        .catch(({ status, msg, data }) => {
          reject({ status, msg, data })
        })
        .finally(() => {
          isLoading.value = false
          // removeController(controller)
        })
    })
  }

  // 组件销毁时取消所有请求
  onUnmounted(() => {
    controllers.forEach((controller) => controller.abort())
  })

  // 对外公开的 API
  defineExpose({ get, post })
</script>

<style scoped>
  .ui-axios {
  }
</style>
